<template>
    <div class="mylive">
        <van-nav-bar class="navBar" fixed :placeholder="true" left-arrow @click-left="$router.go(-1)">
            <template #title>
                <span>我的直播</span>
            </template>
        </van-nav-bar>
        
        <!-- 直播tab -->
        <van-tabs v-model="activeName" @click='tabClick' color="#2470F4">

            <!-- 今日直播 -->
            <van-tab title="今日直播" name="a">
                <div class="todayBox" v-if="todayList.length > 0">

                    <!-- 今日直播卡片 -->
                    <div class="item" v-for="(item, index) in todayList" :key="index">
                        <div class="left">
                            <div class="avatar">
                                <img :src="item.teacherHeaderPath" class="avatar_img" />
                                <div class="avatar_icon"></div>
                            </div>
                            <div class="avatar_text">{{ item.typeName }}</div>
                        </div>
                        <div class="right">
                            <div class="title">{{ item.name }}</div>
                            <div class="time">时间：{{ item.liveTime }}</div>
                            <div class="bottom">
                                <div>
                                    <span class="name">{{ item.teacherName }}</span>
                                    <span class="price">{{ item.isFree == 1 ? "¥"+item.price : "¥免费" }}</span>
                                </div>
                                <div v-if="item.type == 1" class="btn" @click="onYuYue(item)">进入</div>
                                <div v-if="item.type == 2" class="btn" @click="onYuYue(item)">预约</div>
                                <div v-if="item.type == 3" class="btn" @click="onYuYue(item)">购买</div>
                                <div v-if="item.type == 4" class="btn" @click="onYuYue(item)">待开播</div>
                                <div v-if="item.type == 5" class="btn" @click="onYuYue(item)">回放</div>
                            </div>
                        </div>
                    </div>

                </div>
                <van-empty v-else class="custom-image" :image="url" description="暂无数据" />
            </van-tab>

            <!-- 直播回放 -->
            <van-tab title="直播回放" name="b">
                <div class="allBox" v-if="minList.length > 0">
                    <div class="item" v-for="(item, index) in minList" :key="index">
                        <div class="left">
                            <div class="avatar">
                                <img :src="item.teacherHeaderPath" class="avatar_img" />
                                <div class="avatar_icon"></div>
                            </div>
                            <div class="avatar_text">{{ item.typeName }}</div>
                        </div>
                        <div class="right">
                            <div class="title">{{ item.name }}</div>
                            <div class="time">时间：{{ item.liveTime }}</div>
                            <div class="bottom">
                                <div>
                                    <span class="name">{{ item.teacherName }}</span>
                                    <span class="price">{{ item.isFree == 1 ? "¥"+item.price : "¥免费" }}</span>
                                </div>
                                <div v-if="item.type == 1" class="btn" @click="onYuYue(item)">进入</div>
                                <div v-if="item.type == 2" class="btn" @click="onYuYue(item)">预约</div>
                                <div v-if="item.type == 3" class="btn" @click="onYuYue(item)">购买</div>
                                <div v-if="item.type == 4" class="btn" @click="onYuYue(item)">待开播</div>
                                <div v-if="item.type == 5" class="btn" @click="onYuYue(item)">回放</div>
                            </div>
                        </div>
                    </div>
                </div>
                <van-empty v-else class="custom-image" :image="url" description="暂无数据" />
            </van-tab>

            <!-- 直播课程 -->
            <van-tab title="直播课程" name="c">
                <div v-if="ActiveList.length > 0" class="mylive_box">
                    <div class="list">
                        <div class="item" v-for="(course, key) in ActiveList" :key="key" @click="liveTypeJump(course)">
                            <div class="left">
                                <img :src="course.coverPath" class="img" />
                            </div>
                            <div class="right">
                                <div class="title">{{ course.packageName }}</div>
                                <div class="right_icon_box">
                                    <img class="right_icon" src="@/assets/all_Icon/drawable-xxhdpi/icon_arrow_right.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <van-empty v-else class="custom-image" :image="url" description="暂无数据" />
            </van-tab>
        </van-tabs>

    </div>
</template>

<script>
import { coursepackageList, livePlaybackList,liveTodayList } from "@/api/study_course.js";
import { liveYuYue } from "@/api/home.js";
import {kbt} from '@/utils/utils.js'

export default {
    name: 'mylive',
    data() {
        return {
            activeName: "a",

            todayList: [],
            minList: [],
            ActiveList: [],
            
            LoadingToday: false,
            url: require('../../../assets/all_Icon/drawable-xxhdpi/no_data.png')
        }
    },
    created() {
        this.liveTodayList() //获取今日直播
    },
    methods: {
        // tab切换
        tabClick(val) {
            console.log(val);
            if (val == 'a') {
                this.liveTodayList();
            } else if (val == 'b') {
                this.livePlaybackList();
            } else {
                coursepackageList(0).then((res) => {
                    this.ActiveList = res.data.data;
                });

            }
        },

        // 获取今日直播
        liveTodayList() {
            let data = new FormData()
            data.append('page',0)
            liveTodayList(data).then((res) => {
                console.log('获取列表---', res)
                if (res.data.code == 0) {
                    this.todayList = res.data.data; 
                    this.LoadingToday = false 
                }
            })
        },
        // 获取直播回放
        livePlaybackList() {
            let data = new FormData()
            data.append('page',0)
            livePlaybackList(data).then((res) => {
                console.log('获取直播回放列表---', res)
                if (res.data.code == 0) {
                    this.minList = res.data.data
                }
            })
        },

        // 点击预约按钮
        onYuYue(item) {
            console.log('预约直播---------', item)
            window.sessionStorage.setItem('liveName',item.name)
            if (item.type == 1) { //进入直播
                this.$toast('进入直播')
                // this.$router.push({
                //     path: "/zhibo",
                //     query: {
                //         id: item.id, //直播id
                //         coursePackageId: item.coursePackageId,
                //         courseId: item.courseId,
                //         channelId: item.channelId,
                //         liveTime: item.liveTime
                //     }
                // })

                let coursePackageId = ''
                let courseId = ''
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }
                var state = '1';

                let channelId = kbt.encrypt(item.channelId) //直播id加密
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ item.liveTime+ coursePackageId+ courseId+'/'+state+'/'+item.liveType)
            }
            if (item.type == 2) { //预约直播
                let data = new FormData()
                data.append('liveId', item.id)
                liveYuYue(data).then(res => {
                    if (res.data.code == 0) {
                        this.$toast('预约直播成功')
                        this.liveTodayList() //今日
                    }
                })
            }
            if (item.type == 3) { //购买
                this.$router.push({
                    path: '/orderdetail',
                    query: {
                        liveId: item.id,
                        price: item.price
                    }
                })
            }
            if (item.type == 4) { //待开播
                // this.$toast('待开播')
                // this.$router.push({
                //     path: '/zhibo',
                //     query: {
                //         liveId: item.id,
                //         channelId: item.channelId,
                //         coursePackageId: item.coursePackageId,
                //         courseId: item.courseId,
                //         liveTime: item.liveTime
                //     }
                // })
                
                let coursePackageId = ''
                let courseId = ''
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }

                let channelId = kbt.encrypt(item.channelId) //直播id加密
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ item.liveTime+ coursePackageId+ courseId)
                
            }
            if (item.type == 5) { //回放
              
                let coursePackageId = ''
                let courseId = ''
                let state = ''
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }
                if(item.type == 5){
                    state = '2'
                }else{state = 'zb'}

                let channelId = kbt.encrypt(item.channelId) //直播id加密
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ courseId +'/'+state+'/'+item.liveType)
            }
            this.liveTodayList()
        },
        liveTypeJump(item) {
            this.$router.push({
                path: "/liveCurriculum",
                query: {
                    id: item.id
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.mylive {
    width: 100%;
    height: 100%;
    /deep/ .van-empty__image {
        width: 100%;
        height: calc(100vh - 350px);
    }
    .navBar{
        z-index: 999999999;
    }

    .todayBox,
    .allBox {
        min-height: 85vh;
        overflow: auto;

        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #dddddd;
            padding: 18px 0;
            &:last-child {
                border: none;
            }
            .left {
                width: 72px;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                margin-left: 10px;
                .avatar {
                    border-radius: 50%; /*no*/
                    border: 3px solid #fff;
                    background: #fff;
                    width: 72px;
                    height: 72px;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    background: url("../../../assets/all_Icon/drawable-xhdpi/icon_home_live.png")
                        no-repeat;
                    background-size: 100% 100%;
                    .avatar_img {
                        width: 75%;
                        position: absolute;
                        bottom: 6px;
                        object-fit: cover;
                    }
                }
                .avatar_text {
                    position: absolute;
                    bottom: 0;
                    // z-index: 2;
                    background: #f8ab50;
                    padding: 1px 8px;
                    text-align: center;
                    width: 90%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    overflow: hidden;
                    white-space: nowrap;
                    color: #fff;
                    font-size: 12px;
                    border-radius: 500px; /*no*/
                }
            }
            .right {
                flex: 1;
                padding: 0 0 0 21px;
                .title {
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 550;
                    color: #333333;
                    line-height: 22px;
                    margin-bottom: 6px;
                }
                .time {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                }
                .bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #555555;
                        line-height: 19px;
                    }
                    .price {
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #e53d3d;
                        line-height: 18px;
                        margin-left: 10px;
                    }
                    .btn {
                        margin-right: 10px;
                        background: #5d7dff;
                        border-radius: 500px; /*no*/
                        width: 67px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .mylive_box {
        height: calc(100vh - 90px);
        width: 100%;
        overflow-y: auto;
        .list {
            padding: 0px 12px;
            .item {
                display: flex;
                flex-wrap: wrap;
                padding: 14px 0;
                border-bottom: 1px solid #dddddd;
                &:last-child {
                    border: none;
                }
                .left {
                    width: 109px;
                    position: relative;
                    margin-right: 9px;
                    border-radius: 5px; /*no*/
                    .img {
                        width: 100%;
                        border-radius: 5px; /*no*/
                    }
                    .mark {
                        opacity: 0.6;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: #000000;
                        border-radius: 5px 0px 5px 0px; /*no*/

                        font-size: 11px; /*no*/
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        padding: 2px 4px;
                    }
                }
                .right {
                    width: calc(100% - 118px);
                    display: flex;
                    justify-content: space-between;
                    .title {
                        font-size: 15px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 550;
                        color: #333333;
                        width: calc(100% - 50px);
                    }
                    .right_icon_box {
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: right;
                        .right_icon {
                            width: 8px;
                            height: 12px;
                        }
                    }
                    .text {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-wrap: break-word;
                        margin: 6px 0 8px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .question_cirle-continer {
                            width: calc(100% - 63px);
                            height: 10px;
                            background: #e7ecff;
                            border-radius: 5px;
                            .question_cirle-yes {
                                width: 50%;
                                height: 10px;
                                background: #5f7dff;
                                border-radius: 5px;
                            }
                        }
                    }
                    .mark {
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 550;
                        line-height: 18px;
                        display: flex;
                        justify-content: space-between;
                        .mark_title {
                            font-size: 12px;
                            color: #cccccc;
                        }
                        .mark_study {
                            color: #5f7dff;
                        }
                    }
                }
            }
        }
    }
}
</style>
